<template>
  <div class="SignUpView d-flex flex-row">
    <!-- Left Panel !-->
    <div
      class="SignUpView__left-side position-relative overflow-auto"
      data-test-id="left_panel-container"
    >
      <NotificationListManager class="position-fixed SignUpView__notification" />
      <!-- Desktop Logo !-->
      <div
        v-if="step <= 1"
        class="cursor-pointer text-center full-width"
        @click="redirectToWebsite()"
      >
        <Logo class="SignUpView__logo--primary py-4" />
      </div>
      <!-- View -->
      <div class="SignUpView__left-side__wrapper pb-4 mt-md-6">
        <slot />
      </div>
    </div>
    <!-- Right Panel !-->
    <SignUpRightColumn
      :right-image="rightImage"
      :title="rightColumnTitle"
      :booking-type-text="bookingTypeText"
    />
  </div>
</template>
<script>
import NotificationListManager from '@/components/NotificationList/NotificationListManager';
import Logo from '@/components/Logo/Logo';
import { LOGOS_KEYNAMES } from '@/constants/assetsRepository';
import { WEBSITE_DOMAIN } from '@/constants/app';
import SignUpRightColumn from '@Account/SignUp/components/SignUpRightColumn';

export default {
  components: {
    SignUpRightColumn,
    NotificationListManager,
    Logo,
  },
  props: {
    rightImage: {
      type: [String, Object],
      default: '',
    },
    leftTitle: {
      type: String,
      default: '',
    },
    leftSubtitle: {
      type: String,
      default: '',
    },
    isCoverImg: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    rightColumnTitle: {
      type: String,
      default: null,
    },
    bookingTypeText: {
      type: String,
      default: null,
    },
  },
  setup() {
    const logoType = LOGOS_KEYNAMES.CONTRAST_LOGO;
    const redirectToWebsite = () => {
      window.open(WEBSITE_DOMAIN, '_self');
    };
    return {
      logoType,
      redirectToWebsite,
    };
  },
};
</script>
